











































































































import VueBase from '../../VueBase'
import { Component } from 'vue-property-decorator'
import { ProjectListParams } from './types'
import { formatTimestamp } from '@/utils/utils'

@Component({ name: 'ProjectManage' })
export default class ProjectManage extends VueBase {
  private page = 1
  private pageSize = 20
  private total = 0
  private tableData: Array<ProjectListParams> = []
  private searchObj = {
    name: '',
  }

  private getLevel(type: number) {
    switch (type) {
      case 1:
        return 'height'
      case 2:
        return 'middle'
      case 3:
        return 'low'
      case 5:
        return 'info'
      default:
        break
    }
  }

  created() {
    this.getTableData()
  }

  private newSelectData() {
    this.page = 1
    this.getTableData()
  }

  private currentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  private async getTableData() {
    const params = {
      page: this.page,
      pageSize: this.pageSize,
      name: this.searchObj.name,
    }
    this.loadingStart()
    const { status, msg, data, page } = await this.services.project.projectList(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data.reduce(
      (list: Array<ProjectListParams>, item: ProjectListParams) => {
        item.vul_count.sort((a, b) => a.level - b.level)
        list.push({
          ...item,
          latest_time: formatTimestamp(item.latest_time),
        })
        return list
      },
      []
    )
    this.total = page.alltotal
  }
  private async projectDelete(id: number) {
    this.$confirm(this.$t('views.projectManage.deleteConfirm') as string, '', {
      confirmButtonText: this.$t('views.projectManage.delete') as string,
      cancelButtonText: this.$t('views.projectManage.cancel') as string,
      type: 'warning',
    }).then(async () => {
      this.loadingStart()
      const { status, msg } = await this.services.project.projectDelete({ id })
      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      this.$message({
        type: 'success',
        message: this.$t('views.projectManage.deleteSuccess') as string,
        showClose: true,
      })
      this.getTableData()
    })
  }
}
